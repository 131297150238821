const http = require('../http').default

const trackProductAddedToCart = (product) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "add_to_cart",
        ecommerce: {
            currency: "EUR",
            value: product.price * product.quantity,
            items: [
                product
            ]
        }
    });
}

const trackProductRemovedFromCart = (product) => {
    dataLayer.push({ ecommerce: null });
    dataLayer.push({
        event: "remove_from_cart",
        ecommerce: {
            currency: "EUR",
            value: product.price * product.quantity,
            items: [
                product
            ]
        }
    });
}

exports.addProductToCart = async (product_id, quantity, attribute = '') => {
    try {
        const payload = { product_id, quantity, attribute }
        const response = await http.post('/cart/items', payload)

        document.dispatchEvent(new CustomEvent('cartIsUpdated', { detail: response.data }))

        if (window.dataLayer && response.data.product) {
            let product = response.data.product;
            trackProductAddedToCart(product);
        }

        return response.data
    } catch (error) {
        console.log('Failed to add product to Cart:  ', error);
    }
}

exports.trackProductAddedToCart = trackProductAddedToCart;
exports.trackProductRemovedFromCart = trackProductRemovedFromCart;