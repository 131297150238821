const flatpickr = require('flatpickr').default;
const { toggle } = require('el-transition');
const { phoneInput } = require('../lib/phoneInput.js');
// require('flatpickr/dist/l10n/lt').default.lt

exports.process = () => {
    const form = document.getElementById('request-form');

    if (form) {
        let selectIsOpened = null;

        const phoneNumberInput = document.querySelector("#phone_number");
        const phoneNumberInputCode = document.querySelector("#phone_number_code");
        const phoneNumberInputPrefix = document.querySelector("#phone_number_prefix");

        if (![phoneNumberInput, phoneNumberInputCode, phoneNumberInputPrefix].includes(null)) {
            phoneInput({
                input: phoneNumberInput,
                inputCode: phoneNumberInputCode,
                inputPrefix: phoneNumberInputPrefix,
            });
        }

        const dateInputs = document.querySelectorAll('#request-form .js-flatpickr');

        dateInputs.forEach((element) => {

            flatpickr(element, {
                dateFormat: "Y-m-d",
                disable: JSON.parse(element.dataset.disabledDates || '[]'),
            });
        });

        const selects = document.querySelectorAll('#request-form .select');

        const toggleSelectList = (list) => toggle(list).then(() => {
            list.closest('.select').querySelector('button svg').classList.toggle('rotate-180');
        });

        const handleSelectButtonOnClick = (event) => {
            const isExpanded = event.currentTarget.getAttribute('aria-expanded');

            if (isExpanded === 'false') {
                const listId = event.currentTarget.getAttribute('aria-controls');
                const list = document.getElementById(listId);

                let next = event.currentTarget.getAttribute('aria-expanded') === 'true' ? 'false' : 'true';

                event.currentTarget.setAttribute('aria-expanded', next);

                return toggleSelectList(list).then(() => {
                    selectIsOpened = next === 'true' ? listId : null;
                });
            }
        }

        const handleSelectItemOnClick = (event) => {
            const list = document.getElementById(event.currentTarget.dataset.list);

            const input = list.closest('.select').getElementsByTagName('input')[0];

            input.value = event.currentTarget.dataset.value;

            return toggleSelectList(list).then(() => {
                input.dispatchEvent(new Event('change'))
            });
        }

        const handleInputValueOnChange = (event) => {
            const input = document.querySelector(`input[name=${event.target.name}]`);
            const select = input.closest('.select');

            select.querySelectorAll('ul li').forEach((element) => {
                const icon = element.getElementsByClassName('icon').item(0);

                icon.classList.add('hidden');

                element.classList.add('hover:bg-moon-light-grey');
                element.classList.remove('bg-moon-light-grey');

                if (event.target.value === element.dataset.value) {
                    icon.classList.remove('hidden');

                    element.classList.add('bg-moon-light-grey');

                    const selectedValue = select.getElementsByClassName('selected-value').item(0);

                    selectedValue.textContent = element.textContent;
                    selectedValue.classList.remove('text-gray-400');
                    selectedValue.classList.add('text-moon-spoon-brown');
                }
            });

            select.querySelector('button').setAttribute('aria-expanded', 'false')
            selectIsOpened = null;
        }

        selects.forEach((element) => {
            element.querySelector('button').addEventListener('click', handleSelectButtonOnClick);

            element.querySelectorAll('ul li').forEach((listItem) => {
                listItem.addEventListener('click', handleSelectItemOnClick);
            });

            element.querySelector('input').addEventListener('change', handleInputValueOnChange);
        });

        document.onclick = function (event) {
            if (!selectIsOpened) {
                return;
            }

            const list = document.getElementById(selectIsOpened);

            if (!list.contains(event.target)) {
                const select = list.closest('.select');

                return toggleSelectList(list).then(() => {
                    select.querySelector('button').setAttribute('aria-expanded', 'false');
                    selectIsOpened = null;
                });
            }
        }
    }
}
