const setCookie = function (name, value, days) {
    let date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
}

exports.process = () => {
    const cookiesBar = document.querySelector('[data-cookies-bar]')
    const cookiesButton = document.querySelector('[data-cookies-btn]')

    if (!cookiesBar || !cookiesButton) {
        return
    }

    cookiesBar.classList.remove('hidden')
    cookiesBar.classList.add('cookies-slide-in')

    cookiesButton.addEventListener('click', (event) => {
        event.preventDefault()
        setCookie('spm_cookies_bar', true, 365)

        cookiesBar.classList.add('cookies-slide-out')

        cookiesBar.addEventListener('animationend', () => {
            cookiesBar.classList.replace('cookies-slide-out', 'hidden')
        }, { once: true })
    })
}
