const popup = document.querySelector('[data-popup]')
const backdrop = document.querySelector('[data-popup-backdrop]')
const closeButtons = document.querySelectorAll('[data-popup-close-btn]')
const closeLink = document.querySelector('[data-popup-close-link]');
const panel = document.querySelector('[data-popup-panel]')

const setPopupCookie = (timestamp) => {
    const expires = new Date(Date.now() + 365 * 864e5).toUTCString()

    document.cookie = `pop_up_closed=${timestamp}; expires=${expires}; path=/`;
}

const closePopup = () => {
    setPopupCookie(popup.dataset.popupTimestamp);

    backdrop.classList.replace('backdrop-in', 'backdrop-out')
    panel.classList.replace('popup-panel-in', 'popup-panel-out')

    backdrop.addEventListener('animationend', () => {
        popup.classList.add('hidden');
    }, { once: true });
}


exports.process = () => {
    if (!popup) {
        return;
    }

    closeButtons.forEach((closeButton) => {
        closeButton.addEventListener('click', closePopup);
    })

    closeLink.addEventListener('click', closePopup);
}
