const { toggleProductDrawer } = require('./product-drawer');

const productAddToCartForm = document.getElementById('product-add-to-cart-form');

if (productAddToCartForm) {
    productAddToCartForm.onsubmit = (event) => {
        event.preventDefault();

        const formData = new FormData(event.target);

        const product = {
            id: formData.get('id'),
            name: formData.get('name'),
            image: formData.get('image'),
            price: formData.get('price'),
            description: formData.get('description'),
        }

        toggleProductDrawer({
            product,
            quantity: formData.get('quantity'),
            attribute: formData.get('attribute')
        })
    }
}

