require('swiper/css')
require('swiper/css/grid')
require('swiper/css/pagination')
require('swiper/css/navigation')
const { Swiper, Grid, Pagination, Navigation } = require('swiper')

const { Fancybox } = require("@fancyapps/ui")

const bindFancyboxTo = (container) => {
    const gallery = container.getAttribute('data-gallery-container')

    Fancybox.bind(`[data-fancybox="${gallery}"]`, {
        Thumbs: false,
        Toolbar: false,
        closeButton: "top",
        Carousel: { Dots: false }
    })
}

const bindCarouselTo = (container) => {
    const swiper = new Swiper('.carousel', {
        modules: [Grid, Pagination, Navigation],
        slidesPerView: 2,
        spaceBetween: 8,
        grid: { rows: 2, fill: 'row' },
        navigation: {
            nextEl: '.next',
            prevEl: '.prev',
        },
        pagination: {
            type: 'bullets',
            clickable: true,
            el: '.home-slider-pagination',
            bulletClass: 'w-full inline-block rounded-full h-3 w-3 bg-moon-spoon-brown opacity-20',
            bulletActiveClass: '!opacity-100',
            clickableClass: 'slider-bullet-clickable',
            renderBullet: (index, className) => {
                return `<span class="${className}"></span>`
            },
        },
        breakpoints: {
            640: {
                slidesPerView: 6,
                spaceBetween: 14,
            }
        }
    });
}

/**
 * Initializes the homepade tabs and slider gallery.
 *
 * @return {void}
 */
const initTabs = () => {
    const tabs = document.querySelectorAll('[data-gallery-tab]')
    const containers = document.querySelectorAll('[data-gallery-container]')

    bindCarouselTo()
    bindFancyboxTo(containers[0])

    /**
     * Sets the current active tab.
     *
     * @param  {Event} event
     * @param  {Number} current
     * @return {void]
     */
    const setTab = (event, current) => {
        event.preventDefault()

        containers[current].classList.remove('hidden')
        tabs[current].classList.add('bg-moon-spoon-brown', 'text-white')

        containers.forEach((container, idx) => {
            if (current !== idx) {
                container.classList.add('hidden')
            }
        })

        tabs.forEach((tab, idx) => {
            if(current !== idx) {
                tab.classList.remove('bg-moon-spoon-brown', 'text-white')
            }
        })
    }

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', (event) => setTab(event, index))
    })
}

exports.process = () => {
    if (document.querySelectorAll('[data-gallery]').length === 0) {
        return;
    }

    initTabs()
}
