require('./includes/cart').process();
require('./includes/city-select-form');
require('./includes/envelope');
require('./includes/header-nav');
require('./includes/product-filters-form');
require('./includes/product-teaser');
require('./includes/product-details');
require('./includes/recommended-products');
require('./includes/product-photo-gallery');

require('./includes/checkout').process();
require('./includes/n20').process();
require('./includes/popup').process();
require('./includes/cookie-bar').process();
require('./includes/homepage-gallery').process();
require('./includes/product-attribute-selector').process();
require('./includes/product-quantity-selector').process();
require('./includes/request-form').process();

document.addEventListener('cartIsUpdated', (event) => {
    const badge = document.querySelector('.cart-items-count');
    const currency = new Intl.NumberFormat('lt', { style: 'currency', currency: 'EUR' })

    badge.firstElementChild.innerHTML = currency.format(event.detail.cart_sum)
    badge.classList.remove('hidden');
});
