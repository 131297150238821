const popup = document.querySelector('[data-n20]')
const backdrop = document.querySelector('[data-n20-backdrop]')
const closeLink = document.querySelector('[data-n20-close-link]');
const panel = document.querySelector('[data-n20-panel]')

const setN20Cookie = () => {
    const expires = new Date(Date.now() + 864e5).toUTCString()

    document.cookie = `_n20=true; expires=${expires}; path=/`;
}

const closePopup = (event) => {
    event.preventDefault();

    setN20Cookie();

    backdrop.classList.replace('backdrop-in', 'backdrop-out')
    panel.classList.replace('popup-panel-in', 'popup-panel-out')

    popup.classList.add('hidden');
}


exports.process = () => {
    if (!popup) {
        return;
    }

    closeLink.addEventListener('click', closePopup);
}
