const lib = require('../lib/selectors');

const increaseQuantity = (event) => {
    event.preventDefault();

    const input = lib.getInputFromEvent(event, 'div.product-quantity-selector');

    const { step } = input.dataset;

    input.value = parseInt(input.value, 10) + parseInt(step, 10);
    input.dispatchEvent(new Event('change'))
}

const decreaseQuantity = (event) => {
    event.preventDefault();

    const input = lib.getInputFromEvent(event, 'div.product-quantity-selector');

    const { min, step } = input.dataset;

    if ((parseInt(input.value, 10) - parseInt(step, 10)) >= parseInt(min, 10)) {
        input.value = parseInt(input.value, 10) - parseInt(step, 10);
        input.dispatchEvent(new Event('change'))
    }
}

exports.process = () => {
    const elements = lib.getUnprocessedElements('product-quantity-selector');

    elements.forEach(function (element) {
        Array.from(element.getElementsByTagName('button')).forEach(
            function (item) {
                switch (item.dataset.action) {
                    case 'increase':
                        item.onclick = increaseQuantity

                        break;
                    case 'decrease':
                        item.onclick = decreaseQuantity

                        break;
                    default:
                }
            }
        );

        element.classList.add('processed');
    });
}

exports.getQuantityFromSelector = (element) => {
    const input = lib.getInputFromElement(element);

    return input ? parseInt(input.value, 10) : undefined;
}
