const { toggleProductDrawer } = require('./product-drawer');

const increaseQuantity = function (event) {
    const product = event.target.closest('div.product-teaser');

    product.dataset.value = (parseInt(product.dataset.value, 10) + parseInt(product.dataset.step, 10)).toString();

    setQuantityToInputs(product);
}

const decreaseQuantity = function (event) {
    const product = event.target.closest('div.product-teaser');

    const min = parseInt(product.dataset.min, 10);
    const selectedValue = parseInt(product.dataset.value, 10);
    const step = parseInt(product.dataset.step, 10);

    if (selectedValue > step && selectedValue - step >= min) {
        product.dataset.value = (selectedValue - step).toString();
    }

    setQuantityToInputs(product);
}

const handleAddToCartLinkOnClick = (event) => {
    event.preventDefault();

    const element = event.target.closest('div.product-teaser');

    const product = JSON.parse(element.dataset.product);
    const quantity = parseInt(element.dataset.value, 10);

    toggleProductDrawer({ product, quantity })
}

const setQuantityToInputs = (product) => {
    Array.from(product.getElementsByClassName('quantity')).forEach(
        function (item) {
            item.value = product.dataset.value.toString();
        }
    );
}

Array.from(document.getElementsByClassName('product-teaser')).forEach(
    function(product) {
        setQuantityToInputs(product);

        Array.from(product.getElementsByClassName('quantity-action')).forEach(
            function (item) {
                switch (item.dataset.action) {
                    case 'increase':
                        item.onclick = increaseQuantity;

                        break;
                    case 'decrease':
                        item.onclick = decreaseQuantity;

                        break;
                    default:
                }
            }
        );

        Array.from(product.getElementsByClassName('product-teaser-add-to-card')).forEach(
            function (item) {
                item.onclick = handleAddToCartLinkOnClick;
            }
        )
    }
);
