const lib = require('../lib/selectors');

const setActiveLink = (element) => {
    Array.from(element.closest('div.product-attribute-selector').getElementsByTagName('a')).forEach(
        function (link) {
            link.classList.remove('product-attribute-selector-link--active');
        }
    )

    element.classList.add('product-attribute-selector-link--active');
}

const handleLinkOnClick = (event) => {
    event.preventDefault();

    lib.getInputFromEvent(event, 'div.product-attribute-selector').value = event.target.dataset.value;

    setActiveLink(event.target);
}

exports.process = () => {
    const elements = lib.getUnprocessedElements('product-attribute-selector');

    elements.forEach(function (element) {
        const input = lib.getInputFromElement(element)

        Array.from(element.getElementsByTagName('a')).forEach(
            function (item) {
                item.onclick = handleLinkOnClick;

                if (input.value === item.dataset.value) {
                    setActiveLink(item);
                }
            }
        );

        element.classList.add('processed');
    });
}

exports.getAttributeFromSelector = (element) => {
    const input = lib.getInputFromElement(element);

    return input ? input.value : undefined;
}
