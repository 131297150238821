const container = document.getElementById('product-photo-gallery');

if (container) {
    const main = document.getElementById('product-photo-gallery-main');
    const thumbnailLinks = Array.from(document.getElementsByClassName('product-photo-gallery--thumbnail'));

    const handleThumbnailOnClick = (event) => {
        event.preventDefault();

        thumbnailLinks.forEach((item) => {
            item.classList.remove('border-moon-spoon-brown');
            item.classList.add('border-transparent');
        });

        event.target.classList.add('border-moon-spoon-brown');
        event.target.classList.toggle('border-transparent');

        main.style.backgroundImage = `url(${event.target.dataset.src})`
    }

    thumbnailLinks.forEach(function (item) {
        item.onclick = handleThumbnailOnClick;
    });
}
