const { phoneInput } = require('../lib/phoneInput.js');

const handleReviewForm = (form) => {
    const sum = document.querySelector('#sum').dataset.sum;
    const sumPlaceholder = document.querySelector('#sum span');
    const deliveryPrice = document.querySelector('#delivery_price');
    const city = document.querySelector("#city");
    const deliveryOptions = document.querySelectorAll('.delivery-option');
    const deliveryOptionsInputs = document.querySelectorAll('.delivery-option input');

    const handleCityOnChange = (event) => {
        deliveryOptions.forEach((option) => {
            if (event.target.value === option.dataset.city) {
                option.classList.remove('hidden');
            } else {
                option.classList.add('hidden');
            }
        })
    }

    const handleDeliveryOptionOnChange = (event) => {
        sumPlaceholder.innerHTML = (parseFloat(sum) + parseFloat(event.target.dataset.price)).toLocaleString(
            undefined,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            }
        );

        deliveryPrice.value = event.target.dataset.price;
    }

    deliveryOptionsInputs.forEach((optionInput) => {
        optionInput.addEventListener('change', handleDeliveryOptionOnChange);
    })

    city.addEventListener('change', handleCityOnChange);
}

/**
 * Toggle elements whether user is a company.
 *
 * @param  {HTMLElement} form
 * @return {void}
 */
const handleContactForm = (form) => {
    phoneInput({
        input: document.querySelector("#phone_number"),
        inputCode: document.querySelector('#phone_number_code'),
        inputPrefix: document.querySelector('#phone_number_prefix'),
    })

    const fields = document.querySelectorAll('[data-company-required]')

    form.elements.is_company.addEventListener('change', (event) => {
        fields.forEach((field) => field.classList.toggle('hidden'))
    })
}

exports.process = () => {
    const form = document.querySelector('[data-checkout-form]')

    if (!form) {
        return;
    }

    const steps = {
        review: handleReviewForm,
        contact: handleContactForm
    }

    steps[form.dataset.checkoutForm](form)
}
