const envelop = document.getElementById('envelope');

const envelopResizeObserver = new ResizeObserver(function (entries) {
    for (let entry of entries) {
        const innerElement = entry.target.getElementsByTagName('div')[0];

        entry.target.style.height = `${entry.contentRect.width / 4 * 2.25}px`;

        if (innerElement) {
            innerElement.style.position = 'absolute';
            innerElement.style.transform = 'rotate(135deg) skew(15deg, 15deg)';
            innerElement.style.left = `-${entry.contentRect.width * 0.05}px`;
            innerElement.style.top = `-${entry.contentRect.width / 5 * 4.15}px`;
            innerElement.style.width = `${entry.contentRect.width * 1.1}px`;
            innerElement.style.height = `${entry.contentRect.width * 1.1}px`;
        }
    }
})

if (envelop !== null) {
    envelopResizeObserver.observe(envelop);
}
