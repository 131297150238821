const form = document.getElementById('city-select-form');

if (form) {
    const button = document.getElementById('city-select-form-button');
    const list = document.getElementById('city-select-form-list');
    const input = document.getElementById('city-select-form-input');

    button.onclick = function () {
        list.classList.toggle('opacity-0');
        list.classList.toggle('opacity-100');
        list.classList.toggle('-z-10');

        button.classList.toggle('opened');
    };

    Array.from(list.getElementsByTagName('li')).forEach(
        function (item) {
            item.onclick = function (event) {
                list.classList.toggle('opacity-0');
                list.classList.toggle('opacity-100');
                list.classList.toggle('-z-10');

                button.classList.toggle('opened');

                const selected = event.target.closest('li').dataset.value

                if (input.value !== selected) {
                    input.value = selected;

                    form.submit();
                }
            }
        }
    );

    document.onclick = function (event) {
        if (button.isSameNode(event.target) || button.contains(event.target)) {
            return;
        }

        if (button.classList.contains('opened') && !list.contains(event.target)) {
            list.classList.toggle('opacity-0');
            list.classList.toggle('opacity-100');
            list.classList.toggle('-z-10');

            button.classList.toggle('opened');
        }
    }
}
