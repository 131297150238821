const { trackProductAddedToCart, trackProductRemovedFromCart } = require('../lib/cart.js');

const handleExpanderOnClick = (event) => {
    event.preventDefault();

    const controlId = event.currentTarget.getAttribute('aria-controls');
    const controlSumId = `${controlId}_sum`;

    const control = document.getElementById(controlId);

    if (control !== null) {
        control.classList.toggle('hidden');
    }

    const controlSum = document.getElementById(controlSumId);

    if (controlSum !== null) {
        document.getElementById(controlSumId).classList.toggle('hidden');
    }

    event.currentTarget.querySelector('.expander-svg-path').classList.toggle('hidden');
    event.currentTarget.parentNode.querySelector('.category-quantity').classList.toggle('hidden');
}

const getEcommerceData = (element) => {
    const { id, name, price, quantity, value, currency } = element.dataset;

    return {
        id: parseInt(id, 10),
        name,
        price: parseFloat(price),
        quantity: parseInt(quantity, 10),
        value: parseFloat(value),
        currency,
    }
}

const handleQuantitySelectorButtonOnClick = (event) => {
    if (window.dataLayer) {
        const { id, name, price, quantity } = getEcommerceData(event.target.closest('.cart-item-placeholder'));

        const currentQuantity = parseInt(event.currentTarget.closest('form').querySelector('input[name="quantity"]').value, 10);

        if (currentQuantity > quantity) {
            trackProductAddedToCart({
                item_id: id,
                item_name: name,
                price: price,
                quantity: currentQuantity - quantity,
            });
        } else if (currentQuantity < quantity) {
            trackProductRemovedFromCart({
                item_id: id,
                item_name: name,
                price: price,
                quantity: quantity - currentQuantity,
            });
        }
    }
    event.currentTarget.closest('form').submit();
}

const handleDeliveryMethodOnChange = (event) => {
    event.target.closest('.cart-products-group').querySelector('.category-quantity').innerHTML = event.target.value
}

const handleDeleteItemFormOnSubmit = (event) => {
    if (window.dataLayer) {
        const { id, name, price, quantity, value, currency } = getEcommerceData(event.target.closest('.cart-item-placeholder'));

        trackProductRemovedFromCart({
            item_id: id,
            item_name: name,
            price: price,
            quantity: quantity,
        });
    }
}

exports.process = () => {
    const expanders = document.querySelectorAll('.cart-products-group .expander');

    expanders.forEach((expander) => {
        expander.onclick = handleExpanderOnClick;
    });

    const deliveryOptions = document.querySelectorAll('#delivery_options_items input');

    deliveryOptions.forEach((input) => {
        input.onchange = handleDeliveryMethodOnChange;
    });

    const quantitySelectors = document.querySelectorAll('.cart-products-group .product-quantity-selector');

    quantitySelectors.forEach((quantitySelector) => {
        quantitySelector.querySelectorAll('input').forEach((button) => {
            button.addEventListener('change', handleQuantitySelectorButtonOnClick);
        });
   });

    const removeItemForms = document.querySelectorAll('.cart-item-delete-form');

    removeItemForms.forEach((form) => {
        form.addEventListener('submit', handleDeleteItemFormOnSubmit);
    });
}
