const { toggle } = require('el-transition');

const form = document.getElementById('product-filters-form');

if (form) {
    const sortButton = document.getElementById('product-filters-form-sort-button');
    const sortDropdown = document.getElementById('product-filters-form-sort-dropdown');

    if (sortButton && sortDropdown) {
        sortButton.onclick = function () {
            toggle(sortDropdown).then(() => {
                sortButton.classList.toggle('opened');
            });
        }

        document.onclick = function (event) {
            if (sortButton.isSameNode(event.target) || sortButton.contains(event.target)) {
                return;
            }

            if (sortButton.classList.contains('opened') && !sortDropdown.contains(event.target)) {
                toggle(sortDropdown).then(() => {
                    sortButton.classList.toggle('opened');
                });
            }
        }
    }

    const categoryButton = document.getElementById('product-filters-form-category-button');
    const categoryDropdown = document.getElementById('product-filters-form-category-dropdown');

    if (categoryButton && categoryDropdown) {
        categoryButton.addEventListener('click', function () {
            toggle(categoryDropdown).then(() => {
                categoryButton.classList.toggle('opened');
            });
        });

        document.addEventListener('click', function (event) {
            if (categoryButton.isSameNode(event.target) || categoryButton.contains(event.target)) {
                return;
            }

            if (categoryButton.classList.contains('opened') && !categoryDropdown.contains(event.target)) {
                toggle(categoryDropdown).then(() => {
                    categoryButton.classList.toggle('opened');
                });
            }
        });
    }

    const tagsButton = document.getElementById('product-filters-form-tags-button');
    const tagsDropdown = document.getElementById('product-filters-form-tags-dropdown');

    if (tagsButton && tagsDropdown) {
        tagsButton.addEventListener('click', function () {
            toggle(tagsDropdown).then(() => {
                tagsButton.classList.toggle('opened');
            });
        });

        document.addEventListener('click', function (event) {
            if (tagsButton.isSameNode(event.target) || tagsButton.contains(event.target)) {
                return;
            }

            if (tagsButton.classList.contains('opened') && !tagsDropdown.contains(event.target)) {
                toggle(tagsDropdown).then(() => {
                    tagsButton.classList.toggle('opened');
                });
            }
        });
    }

    const mobileFiltersDialogButton = document.getElementById('product-filters-form-mobile-dialog-toggle');
    const mobileFiltersOffCanvasMenuBackdrop = document.getElementById('product-filters-form-off-canvas-menu-backdrop');
    const mobileFiltersOffCanvasMenuWrapper = document.getElementById('product-filters-form-off-canvas-menu-wrapper');
    const mobileFiltersOffCanvasMenu = document.getElementById('product-filters-form-off-canvas-menu');
    const mobileFiltersOffCanvasMenuClose = document.getElementById('product-filters-form-off-canvas-menu-close');

    const toggleMobileFilterMenu = () => Promise.all([
        toggle(mobileFiltersOffCanvasMenuBackdrop),
        toggle(mobileFiltersOffCanvasMenuWrapper),
        toggle(mobileFiltersOffCanvasMenu),
    ]).then(() => {
        mobileFiltersOffCanvasMenuWrapper.classList.toggle('opened');
    });

    mobileFiltersDialogButton.addEventListener('click', toggleMobileFilterMenu);
    mobileFiltersOffCanvasMenuClose.addEventListener('click', toggleMobileFilterMenu);

    const categorySelectors = Array.from(form.getElementsByClassName('category-selector'));

    categorySelectors.forEach(function (item) {
        item.onchange = function (event) {
            categorySelectors.forEach(function (item) {
                if (!event.currentTarget.checked) {
                    item.checked = false;
                } else if (item.value !== event.currentTarget.value) {
                    item.checked = false;
                }
            });

            form.submit();
        }
    });

    const tagSelectors = Array.from(form.getElementsByClassName('tag-selector'));

    tagSelectors.forEach(function (item) {
        item.onchange = function (event) {
            tagSelectors.forEach(function (item) {
                if (item.value === event.currentTarget.value) {
                    item.checked = event.currentTarget.checked
                }
            });

            form.submit();
        }
    });

    const filterItems = Array.from(form.getElementsByClassName('filter-item-clear'));

    filterItems.forEach(function (item) {
        item.onclick = function (event) {
            switch (event.currentTarget.dataset.filter) {
                case 'category':
                    categorySelectors.forEach(function (item) {
                        item.checked = false;
                    });

                    break;
                case 'tag':
                    tagSelectors.forEach(function (item) {
                        if (item.value === event.currentTarget.dataset.value) {
                            item.checked = false;
                        }
                    })

                    break;
                default:
            }

            form.submit();
        }
    });

    const orderByItems = Array.from(form.getElementsByClassName('order-by-item'));
    const orderByValue = document.getElementById('order-by');

    orderByItems.forEach(function (item) {
        item.onclick = function (event) {
            event.preventDefault();

            orderByValue.value = event.currentTarget.dataset.value;

            form.submit();
        }
    });

    const mobileFilters = document.querySelectorAll('#product-filters-mobile button');

    mobileFilters.forEach(function (element) {
        element.addEventListener('click', function (event) {
            if (event.currentTarget.getElementsByTagName('svg')[0]) {
                event.currentTarget.getElementsByTagName('svg')[0].classList.toggle('rotate-0');
                event.currentTarget.getElementsByTagName('svg')[0].classList.toggle('rotate-180');
            }

            document.getElementById(event.currentTarget.getAttribute('aria-controls')).classList.toggle('hidden');
        });
    })
}
