exports.getUnprocessedElements = (className) => {
    const elements = Array.from(document.getElementsByClassName(className));

    return elements.filter((element) => {
        return !element.classList.contains('processed');
    });
}

exports.getInputFromEvent = (event, selector) => {
    if (event.target) {
        const element = event.target.closest(selector);

        if (!element) {
            return undefined;
        }

        return this.getInputFromElement(element);
    }

    return undefined;
}

exports.getInputFromElement = (element) => {
    const elements = element.getElementsByTagName('input');

    if (elements.length) {
        return elements.item(0);
    }

    return undefined;
}
