const http = require('../http').default
const flatpickr = require('flatpickr').default;
const { addProductToCart } = require('../lib/cart');

const drawer = document.querySelector('[data-product-drawer]')
const closeBtn = document.querySelectorAll('[data-close-drawer-btn]')
const container = document.querySelector('[data-product-drawer-container]')
const continueShoppingBtn = document.querySelector('[data-drawer-continue]')
const addToCartBtn = document.querySelector('[data-drawer-add]')

/**
 * Closes the product drawer.
 *
 * @param  {Event} event
 * @return {void}
 */
const close = (event) => {
    event.preventDefault()

    container.classList.replace('cart-drawer-animation-in', 'cart-drawer-animation-out')

    container.addEventListener('animationend', () => {
        drawer.classList.add('hidden')
        container.classList.remove('cart-drawer-animation-out')
    }, { once: true })
}

/**
 * Adds the product to the cart and redirects user to review the order.
 *
 * @param  {Event} event
 * @return {void}
 */
const reviewOrder = (event) => {
    event.preventDefault()
    window.location.href = '/cart'
}

/**
 * Adds prodct to cart and alloes use to continue shopping.
 *
 * @param  {Event} event
 * @return {void}
 */
const continueShopping = (event) => {
    event.preventDefault()
    close(event)
};

/**
 * Set the product details.
 *
 * @param  {Object} product
 * @param  {inetger} quantity
 * @return {void}
 */
const setProduct = (product, quantity) => {
    document.querySelector('[data-product-name]').innerHTML = product.name
    document.querySelector('[data-product-image]').src = product.image
    document.querySelector('[data-product-description]').innerHTML = product.description
    document.querySelector('[data-product-price]').innerHTML = product.price
    document.querySelector('[data-product-quantity]').innerHTML = quantity
}

/**
 * Set both disabled and selected date if exists.
 *
 * @return {Promise}
 */
const handleEventDates = async () => {
    const datepicker = document.querySelector('[data-datepicker="disabled-dates"]')

    const response = await http.get('/api/cart/disabled-dates')
    const { disabled, selected } = response.data.dates

    flatpickr(datepicker, {
        disable: disabled,
        dateFormat: "Y-m-d",
        defaultDate: selected,
        onChange: (dates, date, instance) => {
            http.put('/api/cart/event-date', { event_date: date })
        },
    });
}

/**
 * Handles the product drawer visibility behaviour.
 *
 * @param  {Object} product
 * @return {void}
 */
exports.toggleProductDrawer = async ({ product, quantity, attribute = null }) => {
    if (!drawer) {
        return;
    }

    await addProductToCart(product.id, quantity, attribute)
    await handleEventDates()

    setProduct(product, quantity)
    drawer.classList.toggle('hidden')
    container.classList.add('cart-drawer-animation-in')

    addToCartBtn.addEventListener('click', reviewOrder)
    continueShoppingBtn.addEventListener('click', continueShopping)

    closeBtn.forEach((element) => {
        element.addEventListener('click', close)
    })
}
