const Siema = require('siema');

const container = document.querySelector('.recommended-products');

if (container) {
    const slider = new Siema({
        selector: '.recommended-products-carousel',
        easing: 'ease-out',
        perPage: 4,
        loop: true,
    });

    if (window.innerWidth < 1024) {
        slider.destroy(true);
    }

    container.querySelector('.recommended-products--prev').addEventListener('click', () => {
        slider.prev();
    });

    container.querySelector('.recommended-products--next').addEventListener('click', () => {
        slider.next();
    });

    window.addEventListener('resize',() => {
        if (window.innerWidth >= 1024) {
            slider.init();
        } else {
            slider.destroy(true);
        }
    });
}
