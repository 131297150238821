const intlTelInput = require('intl-tel-input')

const EU_COUNTRIES = [
    "al", "ad", "at", "by", "be", "ba", "bg", "hr", "cz", "dk", "ua", "gb",
    "ee", "fo", "fi", "fr", "de", "gi", "gr", "va", "hu", "is", "ie",
    "it", "lv", "li", "lt", "lu", "mk", "mt", "md", "mc", "me", "nl",
    "no", "pl", "pt", "ro", "ru", "sm", "rs", "sk", "si", "es", "se", "ch",
];

exports.phoneInput = ({input, inputCode, inputPrefix}) => {
    const telInput = intlTelInput(input, {
        separateDialCode: true,
        initialCountry: inputCode.value,
        hiddenInput: 'phone',
        preferredCountries: ['lt'],
        onlyCountries: EU_COUNTRIES,
        utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.min.js',
    });

    input.addEventListener('countrychange', function () {
        const {iso2, dialCode} = telInput.getSelectedCountryData();

        inputCode.value = iso2;
        inputPrefix.value = `+${dialCode}`;
    });

    input.addEventListener('blur', function (event) {
        if (event.target.value.startsWith(inputPrefix.value)) {
            input.value = event.target.value.substring(inputPrefix.value.length);
        }
    });
}


