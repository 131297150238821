const mobileMenuContainer = document.querySelector('[data-mobile-menu]')
const mobileNavClose = document.querySelector('[data-mobile-btn="close"]')
const mobileNavTrigger = document.querySelector('[data-mobile-btn="open"]')
const headerSearchForm = document.querySelector('[data-header-search]')
const desktopMenuExpandable = document.querySelectorAll('[data-desktop-expandable]')
const mobileMenuExpandables = document.querySelectorAll('[data-mobile-expandable]')
const languageSwitcher = document.querySelector('[data-locale-trigger]')
const languageContainer = document.querySelector('[data-locales-container]')
const userMenuTrigger = document.querySelector('[data-user-menu="trigger"]')
const userMenuOptions = document.querySelector('[data-user-menu="options"]')

/**
 * Apply the classes to toggle the sub-menus.
 *
 * @param  {Event} event
 * @param  {HTMLElement} button
 * @return {void}
 */
const toggleMenu = (event, button) => {
    event.preventDefault()

    const clickedOutside = (event) => {
        if (button.contains(event.target) === false) {
            button.querySelector('svg').classList.remove('rotate-180')
            button.parentNode.querySelector('[data-expandable-options]').classList.add('hidden')
            document.removeEventListener('click', clickedOutside)
        }
    }

    button.querySelector('svg').classList.toggle('rotate-180')
    button.parentNode.querySelector('[data-expandable-options]').classList.toggle('hidden')
    document.addEventListener('click', clickedOutside)
}

/**
 * Opens the mobile menu.
 *
 * @param  {string} click
 * @param  {Event} event
 * @return {void}
 */
if (mobileNavTrigger !== null) {
    mobileNavTrigger.addEventListener('click', (event) => {
        mobileMenuContainer.classList.remove('hidden')
        document.documentElement.classList.add('overflow-hidden');
    });
}

/**
 * Closes the mobile menu.
 *
 * @param  {string} click
 * @param  {Event} event
 * @return {void}
 */
if (mobileNavClose !== null) {
    mobileNavClose.addEventListener('click', (event) => {
        mobileMenuContainer.classList.add('hidden')
        document.documentElement.classList.remove('overflow-hidden');
    });
}

/**
 * Binds event listeners to all desktop navigation buttons with sub-menus.
 *
 * @param  {HTMLElement} button [description]
 * @return {void}
 */
desktopMenuExpandable.forEach((button) => {
    button.addEventListener('click', (event) => toggleMenu(event, button))
})

/**
 * Binds event listeners to all mobile navigation buttons with sub-menus.
 *
 * @param  {HTMLElement} button
 * @return {void}
 */
mobileMenuExpandables.forEach((button) => {
    button.addEventListener('click', (event) => toggleMenu(event, button))
})

/**
 * Toggles the language switcher.
 *
 * @param  {string} click
 * @param  {Event} event
 * @return {void}
 */
if (languageSwitcher !== null) {
    languageSwitcher.addEventListener('click', (event) => {
        const clickedOutside = (evt) => {
            if (languageSwitcher.contains(evt.target) === false) {
                languageContainer.classList.add('hidden')
                document.removeEventListener('click', clickedOutside)
            }
        }

        languageContainer.classList.toggle('hidden')
        document.addEventListener('click', clickedOutside)
    })
}

if(userMenuTrigger !== null) {
    userMenuTrigger.addEventListener('click', (event) => {
        const clickedOutside = (evt) => {
            if (userMenuTrigger.contains(evt.target) === false) {
                userMenuOptions.classList.add('hidden')
                document.removeEventListener('click', clickedOutside)
            }
        }

        userMenuOptions.classList.toggle('hidden')
        document.addEventListener('click', clickedOutside)
    })
}

/**
 * Toggles the header search Input
 */
if (headerSearchForm !== null) {
    const button = document.querySelector('[data-header-search="button"]')

    button.addEventListener('click', (event) => {
        const input = button.getElementsByTagName('input')[0]

        const clickedOutside = (evt) => {
            if (headerSearchForm.contains(evt.target) === false) {
                button.classList.remove('w-36', 'md:w-56')
                button.classList.add('w-10', 'md:w-14', 'justify-center')
                input.classList.replace('inline', 'hidden')

                document.removeEventListener('click', clickedOutside)
            }
        }

        button.classList.remove('w-10', 'md:w-14', 'justify-center')
        button.classList.add('w-36', 'md:w-56')

        input.classList.replace('hidden', 'inline')
        input.focus()

        document.addEventListener('click', clickedOutside)
    })
}
